import React from 'react'
import ReactSelect from './apply page/ReactSelect'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Datetime from 'react-datetime'
import useAuth from '../firebase/useAuth'
import firebase from '../firebase'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import Spinner from './Spinner'
import ErrorModal from '../components/ErrorModal'
import { Link } from 'gatsby'
import AttachFile from '@material-ui/icons/AttachFile'
import FreelancerRemoteRate from './apply page/FreelancerRemoteRate'
import FreelancerOnSiteRate from './apply page/FreelancerOnSiteRate'
import TextInput from './TextInput'
import CB from './Checkbox'

const Profile = () => {
  const [form, setValues] = React.useState({
    name: '',
    email: '',
    telephone: '',
    linkedIn: '',
    gitHub: '',
    skillSets: '',
    fileName: '',
    availability: {},
  })
  const [share, setShare] = React.useState(false)
  const [Gdpr, setGdpr] = React.useState(true)
  const [role, setRole] = React.useState('load')
  const [renderUpate, setRenderUpdate] = React.useState(false)
  const [cvURL, setCvURL] = React.useState('')
  const [fileName, setFileName] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const {
    name,
    email,
    telephone,
    linkedIn,
    gitHub,
    skillSets,
    cv,
    availability,
  } = form

  React.useEffect(() => {}, [form, share, Gdpr, cvURL])
  const [remoteRate, setRemoteRate] = React.useState(20)
  const [remoteRateNotApplicable, setremoteRateNotApplicable] = React.useState(
    false
  )
  const [onSiteRate, setOnSiteRate] = React.useState(20)
  const [onSiteRateNotApplicable, setonSiteRateNotApplicable] = React.useState(
    false
  )

  const getRemoteRate = remoteRate => {
    setRemoteRate(remoteRate)
  }

  const getremoteRateNotApplicable = () => {
    setremoteRateNotApplicable(!remoteRateNotApplicable)
  }

  const getOnSiteRate = onSiteRate => {
    setOnSiteRate(onSiteRate)
  }

  const getonSiteRateNotApplicable = () => {
    setonSiteRateNotApplicable(!onSiteRateNotApplicable)
  }

  const user = useAuth()

  const onValuePass = value => {
    setValues({ ...form, skillSets: value })
  }

  const GDPRlabel = (
    <span>
      I agree to Flexatal’s storage of my data and have read the
      <Link style={{ color: '#ff9000' }} to="/privacy">
        {' '}
        privacy statement
      </Link>
    </span>
  )

  const getFile = event => {
    let file = event.target.files[0]
    var storageRef = firebase.storage.ref(`CV/${email}/${file.name}`)
    if (file) {
      setFileName(file.name)
      const uploadTask = storageRef.put(file)
      uploadTask.on(
        'state_changed',
        snapshot => {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          console.log(progress)
        },
        error => {
          console.log('Problem uploading the file, Try again!')
        },
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
            setValues({ ...form, cv: downloadURL, fileName: file.name })
          })
        }
      )
    }
  }

  const renderProfileDetails = () => {
    if (!renderUpate && user) {
      if (user.email) {
        const userRef = firebase.db.collection('users').doc(user.email)

        userRef.get().then(doc => {
          if (doc.exists) {
            setRemoteRate(doc.data().remoteRate)
            setOnSiteRate(doc.data().onSiteRate)
            if (doc.data().role === 'Freelancer') {
              setRole('freelancer')
              if (doc.data().name) {
                if (doc.data().name.length > 0) {
                  const {
                    name,
                    email,
                    telephone,
                    linkedIn,
                    gitHub,
                    skillSets,
                    Gdpr,
                    share,
                    cv,
                    fileName,
                    availability,
                  } = doc.data()

                  const aValue = []

                  if (skillSets) {
                    for (let i = 0; i < skillSets.length; i++) {
                      let x = skillSets[i]

                      aValue.push({ value: x, label: x })
                    }
                  }

                  setValues({
                    name,
                    email,
                    telephone,
                    linkedIn,
                    gitHub,
                    skillSets: aValue,
                    cv,
                    fileName,
                    availability,
                  })
                  setGdpr(Gdpr)
                  setShare(share)
                  setRenderUpdate(true)
                }
              }
            } else if (doc.data().role === 'Client') {
              setRole('client')
              if (doc.data().name !== '') {
                const { name, email, telephone } = doc.data()

                setValues({
                  name,
                  email,
                  telephone,
                })
                setRenderUpdate(true)
              }
            }
          }
        })
      }
    }
  }

  React.useEffect(() => {
    renderProfileDetails()
  }, [user, form, role])

  const [isShowing, setIsShowing] = React.useState(false)

  let yesterday = Datetime.moment().subtract(1, 'day')
  let valid = function(current) {
    return current.isAfter(yesterday)
  }

  const openModalHandler = () => {
    setIsShowing(true)
  }

  const closeModalHandler = () => {
    setIsShowing(false)
  }

  const saveProfile = async () => {
    if (role === 'freelancer') {
      if (
        name !== '' &&
        email !== '' &&
        telephone !== '' &&
        typeof availability !== 'undefined' &&
        Gdpr !== false
      ) {
        const aValue = []

        for (let i = 0; i < skillSets.length; i++) {
          let x = skillSets[i].value
          aValue.push(x)
        }

        const ProfileDetail = {
          name,
          email,
          telephone,
          skillSets: aValue,
          linkedIn,
          gitHub,
          availability,
          cv,
          remoteRate,
          remoteRateNotApplicable,
          onSiteRate,
          onSiteRateNotApplicable,
          Gdpr,
          fileName,
          share,
        }
        setLoading(true)
        const userRef = firebase.db.collection('users').doc(user.email)
        const freeLancerRef = firebase.db
          .collection('freelancer')
          .doc(user.email)

        await userRef.set(ProfileDetail, { merge: true })
        await freeLancerRef.set(ProfileDetail, { merge: true })
        await navigate('/successprofile')
        setLoading(false)
      } else {
        openModalHandler()
      }
    } else if (role === 'client') {
      if (name !== '' && email !== '' && telephone !== '') {
        const ProfileDetail = {
          name,
          email,
          telephone,
        }

        const userRef = firebase.db.collection('users').doc(user.email)
        const clientRef = firebase.db.collection('client').doc(user.email)
        await userRef.set(ProfileDetail, { merge: true })
        await clientRef.set(ProfileDetail, { merge: true })
        navigate('/successprofile')
      } else {
        openModalHandler()
      }
    }
  }

  if (role === 'load') {
    return <Spinner />
  } else if (loading) {
    return <Spinner />
  } else {
    return (
      <Wrapper>
        <ErrorModal show={isShowing} close={closeModalHandler}>
          {role === 'client'
            ? 'All fields are required'
            : 'Name, Email, Telephone Number, avaliability and accepting our privacy policy are required'}
        </ErrorModal>
        <form
          onSubmit={e => {
            e.preventDefault()
          }}
        >
          <Header>Edit Profile</Header>

          <div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <TextInput
                  type="text"
                  placeholder="Your Name..."
                  onChange={e => setValues({ ...form, name: e.target.value })}
                  value={form.name}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <TextInput
                  type="text"
                  placeholder="Your Email..."
                  onChange={e => setValues({ ...form, email: e.target.value })}
                  value={form.email}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <TextInput
                  type="text"
                  placeholder="Your Telephone Number..."
                  onChange={e =>
                    setValues({ ...form, telephone: e.target.value })
                  }
                  value={form.telephone}
                />
              </GridItem>
            </GridContainer>
            {role === 'freelancer' && (
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <ReactSelect
                    setValue={form.skillSets}
                    onValuePass={value => onValuePass(value)}
                  />
                </GridItem>
              </GridContainer>
            )}
            {role === 'freelancer' && (
              <>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={6}>
                    <FreelancerRemoteRate
                      remoteRateNotApplicable={remoteRateNotApplicable}
                      getremoteRateNotApplicable={getremoteRateNotApplicable}
                      getRemoteRate={getRemoteRate}
                      remoteRate={remoteRate}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={6}>
                    <FreelancerOnSiteRate
                      onSiteRateNotApplicable={onSiteRateNotApplicable}
                      getonSiteRateNotApplicable={getonSiteRateNotApplicable}
                      getOnSiteRate={getOnSiteRate}
                      onSiteRate={onSiteRate}
                    />
                  </GridItem>
                </GridContainer>
              </>
            )}
          </div>
          {role === 'freelancer' && (
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <TextInput
                  type="text"
                  placeholder="LinkedIn Profile"
                  onChange={e =>
                    setValues({ ...form, linkedIn: e.target.value })
                  }
                  value={form.linkedIn}
                />
              </GridItem>
            </GridContainer>
          )}
          {role === 'freelancer' && (
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <TextInput
                  type="text"
                  placeholder="GitHub Profile"
                  onChange={e => setValues({ ...form, gitHub: e.target.value })}
                  value={form.gitHub}
                />
              </GridItem>
            </GridContainer>
          )}

          {role === 'freelancer' && (
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div
                  style={{
                    margin: '4% 0',
                    display: 'flex',
                    flexDirection: 'row',
                    borderBottom: '1px solid #fff',
                  }}
                >
                  <StyledInput>
                    {form.fileName === '' ? (
                      <>
                        Upload new CV{' '}
                        <AttachFile style={{ color: '#ff9000' }} />
                      </>
                    ) : (
                      form.fileName
                    )}

                    <input
                      type="file"
                      onChange={getFile}
                      style={{ display: 'none' }}
                    />
                  </StyledInput>
                </div>
              </GridItem>
            </GridContainer>
          )}
          {role === 'freelancer' && (
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <Datetime
                  inputProps={{
                    placeholder: 'Availability From',
                    readOnly: true,
                  }}
                  onChange={moment =>
                    setValues({
                      ...form,
                      availability:
                        'Availabilty from ' +
                        moment._d.toLocaleString('en-GB', {
                          timeZone: 'UTC',
                          hour12: false,
                          month: 'short',
                          year: 'numeric',
                          day: 'numeric',
                        }),
                    })
                  }
                  timeFormat={false}
                  isValidDate={valid}
                  dateFormat="LL"
                  value={form.availability}
                  closeOnSelect={true}
                />
              </GridItem>
            </GridContainer>
          )}
          {role === 'freelancer' && (
            <div style={{ marginTop: '4%' }}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <CB
                    checked={share}
                    color="#ff9000"
                    onChange={() => setShare(prevValue => !prevValue)}
                    label="I agree to my profile being shared on Flexatal’s website"
                  />
                </GridItem>
              </GridContainer>
            </div>
          )}
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <CB checked={Gdpr} label={GDPRlabel} color="#ff9000" />
            </GridItem>
          </GridContainer>

          <ButtonWrap>
            <Button
              onClick={() => saveProfile()}
              round
              color="warning"
              size="lg"
            >
              Save
            </Button>
          </ButtonWrap>
        </form>
      </Wrapper>
    )
  }
}
export default Profile

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
  background-color: #232323;
  padding: 0 5%;
`

const Header = styled.h1`
  font-size: 5vw;
  color: #ffffff;
  padding: 5% 0 3% 0;
  font-weight: bold;
  text-align: center;
  border-radius: 16px;
`

const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 5%;
`

const StyledInput = styled.label`
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  color: white;
  padding: 4px 0;
`
